#masteradmin-page {
  .page-content {
    padding: 10px 50px 0px 50px;
  }

  #menu-list {
    background: #f5f1f1;
    margin-top: 10px;
    button {
      margin-left: 10px;
    }
  }

  .sub-pages {
    padding: 30px;
  }
}
