#landing-page {
  .page-content {
    margin-top: 0px;
  }
  .landing-first-fit-view {
    margin-top: 50px;
    @media screen and (max-width: 767px) {
      margin-top: 10px;
    }
    // height: 100vh;
    // background: #1d66ff;
    // color: white;
    // padding-top: 25vh;
    // background-image: url("../images/landing-parking.jpg");
    // background-repeat: no-repeat;
    // background-size: cover;
    // z-index: 1;
  }
  .bg {
    height: 100vh;
    background: #1d66ff;
    color: white;
    padding-top: 25vh;
    background-image: url("../images/landing-parking.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    // z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.08;
    width: 100%;
    height: 100%;
  }

  #landing-information {
    text-align: center;
    @media screen and (max-width: 767px) {
      padding: 10px;
    }
    .landing-heading-1 {
      color: black;
      font-size: 55px;
      margin-top: 10px;
      font-weight: bold;
      @media screen and (max-width: 767px) {
        font-size: 25px;
      }
    }
    .landing-heading-2 {
      color: black;
      font-size: 40px;
      margin-top: 10px;
      @media screen and (max-width: 767px) {
        font-size: 16px;
      }
    }
    .landing-heading-3 {
      color: black;
      font-size: 30px;
      margin-top: 10px;
      @media screen and (max-width: 767px) {
        font-size: 16px;
      }
    }
  }

  #landing-search-box {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;

    .location-search-box {
      width: 50%;

      .ant-select-selector {
        padding: 30px;
        height: 100%;
        font-size: 30px;
        text-align: left;
        padding-left: 14px;
        box-shadow: 0px 15px 10px -15px #111;
        @media screen and (max-width: 767px) {
          padding: 2px;
          font-size: 18px;
          padding-left: 14px;
        }

        input {
          height: 100%;
        }

        .ant-select-selection-placeholder {
          line-height: 40px;
        }
      }

      @media screen and (max-width: 767px) {
        width: 90%;
      }
    }
  }

  #landing-how-it-works {
    color: black;
    box-shadow: 0px -15px 10px -15px #111;
    background: #f9f5f5;
    padding-top: 40px;
    h1 {
      text-align: center;
      font-size: 30px;
    }
    .how-it-work-steps {
      box-shadow: 0px -15px 10px -15px #111;
      box-shadow: 15px 0px 10px -15px #111;
      padding: 30px;
      font-weight: 600px;
      font-size: 18px;
    }
  }

  #list-my-location {
    margin-top: 20px;
    font-weight: bold;
    font-style: italic;
    a {
      color: red;
    }
  }
}
