#mydashboard-page {
  .page-content {
    padding: 10px 50px 0px 50px;
    @media screen and (max-width: 767px) {
      // margin-top: 170px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  #menu-list {
    background: #f5f1f1;
    margin-top: 10px;
    button {
      margin-left: 10px;
    }
  }

  #form-add-newparking {
    margin-top: 20px;
    h3 {
      text-align: center;
      font-size: 20px;
    }
    form {
      width: 50%;
      margin: 0 auto;
      margin-top: 30px;
      @media screen and (max-width: 767px) {
        width: 98%;
      }
    }
    button {
      width: 100%;
    }
    .ant-form-item-label {
      text-align: left;
    }
  }

  #form-edit-parking {
    margin-top: 20px;
    .ant-form-item-label {
      text-align: left;
    }
  }

  .mydashboard-menu {
    margin-top: 10px;
  }
  #my-parkings {
    margin-top: 20px;

    .my-added-parking {
      box-shadow: 0 15px 10px -15px gray;
      margin-bottom: 20px;

      table {
        color: black !important;
      }
    }
  }
}

#email-verification-block {
  background: yellow;
  padding: 10px;
  font-weight: bold;
  button {
    margin-left: 5px;
  }
}
