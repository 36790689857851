#search-page {
  .page-content {
    margin-top: 0px;
    padding: 10px 50px 0px 50px;

    @media screen and (max-width: 767px) {
      // margin-top: 150px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  #location-details {
    background: #f9f5f5;
    margin-top: 20px;
    padding: 20px;
    box-shadow: 0 15px 10px -15px gray;
  }
  #location-stats {
    background: #f9f5f5;
    margin-top: 20px;
    padding: 20px;
    box-shadow: 0 15px 10px -15px gray;
  }
  #location-parkings {
    background: #f9f5f5;
    margin-top: 20px;
    padding: 20px;
    // box-shadow: 0 15px 10px -15px gray;
  }
  .parking-listing-block {
    box-shadow: 0 15px 10px -15px gray;
    table {
      color: black !important;
      tr:nth-child(even) {
        background-color: #f2f2f2;
      }
    }
  }
}
