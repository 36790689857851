#authpage-page {
  .page-content {
    padding: 50px 50px 0px 50px;
  }

  .heading-1 {
    color: gray;
    font-size: 20px;
    margin-bottom: 10px;
    text-align: center;
    @media screen and (max-width: 767px) {
      font-size: 25px;
    }
  }

  #form-login {
    width: 30%;
    margin: 0 auto;
    @media screen and (max-width: 767px) {
      margin-top: 20px;
      width: 100%;
    }
    button {
      width: 100%;
    }
  }

  #form-register {
    width: 40%;
    margin: 0 auto;
    @media screen and (max-width: 767px) {
      margin-top: 20px;
      width: 100%;
    }
    button {
      width: 100%;
    }
  }
}
