#main-sticky-header {
  padding: 20px;

  // position: fixed;
  z-index: 1;
  width: 100%;
  // padding: 0px;
  background: #f9f5f5;
  z-index: 1;
  box-shadow: 0px 15px 10px -15px #111;

  // height: 80px;

  // #main-sticky-header {
  // height: 80px;
  // }
  .logo {
    // margin-left: 60px;
    width: 150px;
    // margin-top: 8px;
    @media screen and (max-width: 767px) {
      // margin-bottom: 28px;
      width: 150px;
    }
  }
  @media screen and (max-width: 767px) {
    // height: 100px;
    // border-bottom: 1px solid black;
    padding: 5px;

    #main-sticky-header .logo {
      margin-left: 0px;
      margin-top: 10px;
    }

    // #landing-information {
    //   /* margin-top: 60px; */
    // }
  }

  .bg-header {
    height: 100vh;
    background: #1d66ff;
    color: white;
    // padding-top: 25vh;
    background-image: url("../images/landing-parking.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.03;
    width: 100%;
    height: 100%;
  }
}
