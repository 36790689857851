.ant-layout {
  background: white;
}
.page-content {
  margin-top: 0px;
}

.email-verification-block {
  margin-top: 10px;
  font-style: italic;
  color: red;
  font-weight: 700;
}

#footer-shortshout {
  img {
    height: 20px;
    margin-top: -9px;
    margin-left: 5px;
  }
}
