@import "~antd/dist/antd.css";

body {
  /* background: aliceblue; */
  /* font-family: "Alegreya", serif !important; */
  font-family: "Cairo", sans-serif !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.site-layout {
  padding: 0 50px;
  margin-top: 64px;
}

.auth-block {
  text-align: right;
  /* padding-right: 20px; */
  /* padding-top: 10px; */
}

.home-page-heading {
  background: aliceblue;
  box-shadow: 0 4px 80px #1890ff;
  min-width: 33%;
  flex-basis: calc(559440px - 99900%);
  margin: 0.5rem;
  border-radius: 8px;
  padding: 1.3rem;
  font-size: 16px;
  margin-top: 40px;
}
.dashboard-sample-view {
  padding-top: 50px;
}

.managelinks-block {
  margin-top: 50px;
}

.recenlty-added-users-list {
  margin-top: 50px;
  padding: 10px;
}

.recenlty-added-users-list .title {
  margin-bottom: 20px;
}

.recently-added-user-avtar {
  margin-top: 20px;
}

.footer-social-icons {
  font-size: 20px;
  margin: 5px;
}

.my-stats {
  margin-bottom: 20px;
  background: white;
  padding: 20px;
}

.dashboard-sample-view {
  text-align: center;
}

.shortshout-link-buttton {
  margin-bottom: 10px;
  background: #1890ff;
  cursor: pointer;
  box-shadow: 3px 5px #82a8c6;
}

.shortshout-link-buttton:active {
  box-shadow: none;
}

@media screen and (max-width: 767px) {
  .add-new-link-button {
    width: 100%;
  }
  .recenlty-added-users-list .title {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }
  .home-page-info-content {
    margin-top: 40px;
  }
  .home-page-heading {
    margin-top: 20px;
  }
  .site-layout {
    padding: 10px;
    margin-top: 64px;
  }

  .fix-header-row {
    text-align: center;
    /* height: 120px; */
  }
  .auth-block {
    text-align: center;
    /* padding-right: 0px; */
    /* background-color: #f5f1f1; */
    /* margin-top: -23px; */
    /* border-bottom: 1px solid black; */
    /* font-size: 12px; */
    /* padding: 2px; */
  }
  .managelinks-block {
    margin-top: 80px;
  }
  .dashboard-sample-view {
    text-align: center;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.sample-short-shout-box {
  border: 2px solid #1890ff;
  border-radius: 50px;
  max-width: 300px;
  margin: 0 auto;
  height: 75vh;
  overflow-y: auto;
}

.sample-short-shout-box::-webkit-scrollbar {
  display: none;
}

#landing-page .site-layout {
  padding: 0px;
}
